import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    autoOpen: { type: Boolean, default: false },
    hoist: { type: Boolean, default: false },
  }

  initialize() {
    if (this.#shouldHoist) {
      this.#hoist()
    }
  }

  connect() {
    if (this.autoOpenValue) {
      this.open()
    }
  }

  open() {
    this.element.showModal()
  }

  close() {
    this.element.close()
  }

  destroy() {
    this.element.remove()
  }

  get #shouldHoist() {
    return this.hoistValue && this.element.parentElement !== document.body
  }

  #hoist() {
    document.body.appendChild(this.element)
  }
}
