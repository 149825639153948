import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['radioSelected', 'radioUnselected', 'input', 'inputArea']

  connect() {
    this.sync()
  }

  sync() {
    if (this.radioSelectedTarget.checked) {
      this.enable()
    } else {
      this.disable()
    }
  }

  enable() {
    this.inputAreaTarget.classList.remove('hidden')
    this.inputTargets.forEach((c) => (c.disabled = false))
  }

  disable() {
    this.inputTargets.forEach((c) => (c.disabled = true))
    this.inputAreaTarget.classList.add('hidden')
  }
}
