import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    'select',
    'libraryName',
    'libraryDescription',
    'phaseName',
    'importTaskDynamicFields',
  ]
  static values = {
    importType: String,
    url: String,
  }

  connect() {
    this.toggleLibraryNameVisibility(this.selectTarget.value)
    this.toggleLibraryDescriptionVisibility(this.selectTarget.value)
  }

  importToChanged(event) {
    if (this.importTypeValue === 'task') {
      if (event.target.value === '') {
        this.importTaskDynamicFieldsTarget.classList.add('hidden')
      }

      get(this.urlValue, {
        query: { import_to_id: event.target.value },
        responseKind: 'turbo-stream',
      })
    }

    this.toggleLibraryNameVisibility(event.target.value)
    this.toggleLibraryDescriptionVisibility(event.target.value)
  }

  importToPhaseChanged(event) {
    this.togglePhaseNameVisibility(event.target.value)
  }

  toggleLibraryDescriptionVisibility(selectedOption) {
    if (selectedOption === '') {
      this.libraryDescriptionTarget.querySelector('input').disabled = false
      this.libraryDescriptionTarget.classList.remove('hidden')
    } else {
      this.libraryDescriptionTarget.querySelector('input').disabled = true
      this.libraryDescriptionTarget.classList.add('hidden')
    }
  }

  toggleLibraryNameVisibility(selectedOption) {
    if (selectedOption === '') {
      this.libraryNameTarget.querySelector('input').disabled = false
      this.libraryNameTarget.classList.remove('hidden')
    } else {
      this.libraryNameTarget.querySelector('input').disabled = true
      this.libraryNameTarget.classList.add('hidden')
    }
  }

  togglePhaseNameVisibility(selectedOption) {
    if (selectedOption === '') {
      this.phaseNameTarget.classList.remove('hidden')
      this.phaseNameTarget.querySelector('input').disabled = false
    } else {
      this.phaseNameTarget.classList.add('hidden')
      this.phaseNameTarget.querySelector('input').disabled = true
    }
  }
}
