import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'dropzone',
    'dropzoneFileInput',
    'label',
    'progress',
    'fileTemplate',
    'file',
    'files',
  ]
  static values = { singleFileMessage: String, standalone: Boolean }

  connect() {
    this.onUploadsStart = this.onUploadsStart.bind(this)
    this.onUploadsEnd = this.onUploadsEnd.bind(this)
    this.onUploadProgress = this.onUploadProgress.bind(this)
    this.onUploadEnd = this.onUploadEnd.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onSubmitEnd = this.onSubmitEnd.bind(this)

    this.progressControllerElement = this.progressTarget.querySelector(
      '[data-controller="ui--progress"]'
    )

    this.form.addEventListener('direct-uploads:start', this.onUploadsStart)
    this.form.addEventListener('direct-uploads:end', this.onUploadsEnd)
    this.form.addEventListener('submit', this.onSubmit)
    this.form.addEventListener('turbo:submit-end', this.onSubmitEnd)

    this.resetMultipleFilesProgressControl()

    if (this.standaloneValue) {
      this.dropzoneFileInputTarget.addEventListener(
        'direct-upload:progress',
        this.onUploadProgress
      )
      this.dropzoneFileInputTarget.addEventListener(
        'direct-upload:end',
        this.onUploadEnd
      )
    }
  }

  disconnect() {
    this.form.removeEventListener('direct-uploads:start', this.onUploadsStart)
    this.form.removeEventListener('direct-uploads:end', this.onUploadsEnd)

    if (this.standaloneValue) {
      this.form.removeEventListener('turbo:submit-end', this.onSubmitEnd)

      this.dropzoneFileInputTarget.removeEventListener(
        'direct-upload:progress',
        this.onUploadProgress
      )
      this.dropzoneFileInputTarget.removeEventListener(
        'direct-upload:end',
        this.onUploadEnd
      )
    }

    this.progressControllerElement = null
    this.onUploadsStart = null
    this.onUploadsEnd = null
    this.onUploadProgress = null
    this.onUploadEnd = null
  }

  fileTargetConnected(target) {
    target.addEventListener('direct-upload:progress', this.onUploadProgress)
    target.addEventListener('direct-upload:end', this.onUploadEnd)

    if (target.dataset.templateFile) {
      this.addFileSizeToTotalSizeToUpload(
        target.querySelector('input[type=file]').files
      )
    }
  }

  fileTargetDisconnected(target) {
    target.removeEventListener('direct-upload:progress', this.onUploadProgress)
    target.removeEventListener('direct-upload:end', this.onUploadEnd)
  }

  onUploadsStart(event) {
    const submit = event.target.querySelector('button[type="submit"]')
    if (submit) {
      submit.disabled = true
    }

    this.labelTarget.classList.add('hidden')
    this.progressTarget.classList.add('flex')
    this.progressTarget.classList.remove('hidden')
    this.dropzoneTarget.classList.remove('border', 'bg-white')
    this.dropzoneTarget.classList.add('bg-neutral-50')

    event.target
      .querySelectorAll('.UI-Dropzone-File-HideOnProgress')
      .forEach((el) => el.classList.add('hidden'))

    event.target
      .querySelectorAll('.UI-Dropzone-File-Progress')
      .forEach((progress) => {
        progress.classList.remove('hidden')
        progress.textContent = '0%'
      })

    event.target
      .querySelectorAll('.UI-Dropzone-Show-On-Upload-Started')
      .forEach((progress) => {
        progress.classList.remove('hidden')
      })
  }

  onUploadsEnd(event) {
    const submit = event.target.querySelector('button[type="submit"]')
    if (submit) {
      submit.disabled = false
    }

    this.dropzoneTarget.classList.add('border', 'bg-white')
    this.dropzoneTarget.classList.remove('bg-neutral-50')
    this.labelTarget.classList.remove('hidden')
    this.progressTarget.classList.remove('flex')
    this.progressTarget.classList.add('hidden')

    this.updateProgress(0)
    this.resetMultipleFilesProgressControl()

    event.target
      .querySelectorAll('.UI-Dropzone-Hide-On-Upload-Ended')
      .forEach((progress) => {
        progress.classList.add('hidden')
      })
  }

  onUploadProgress(event) {
    if (!this.standaloneValue) {
      this.updateSpecificFileUploadProgress(event)
    }

    this.updateGlobalUploadProgress(event)
  }

  onUploadEnd(event) {
    const percentualFractionOfTotalSize =
      (100 / this.totalSizeFilesUploading) * event.detail.file.size
    this.totalPercentageAlreadyUploadedFiles += percentualFractionOfTotalSize
  }

  onFileInputChange(event) {
    this.processFiles(event)
  }

  onDragEnter(event) {
    this.preventDefaultBehaviorAndStopPropagation(event)

    if (this.form.hasAttribute(this.processingAttribute)) {
      return
    }

    this.highlightDropzone()
  }

  onDragOver(event) {
    this.preventDefaultBehaviorAndStopPropagation(event)

    if (this.form.hasAttribute(this.processingAttribute)) {
      return
    }

    this.highlightDropzone()
  }

  onDragLeave(event) {
    this.preventDefaultBehaviorAndStopPropagation(event)

    if (this.form.hasAttribute(this.processingAttribute)) {
      return
    }

    this.unhighlightDropzone()
  }

  onDrop(event) {
    this.preventDefaultBehaviorAndStopPropagation(event)

    if (this.form.hasAttribute(this.processingAttribute)) {
      return
    }

    this.unhighlightDropzone()

    const files = event.dataTransfer.files
    if (files.length > 1 && !this.dropzoneFileInputTarget.multiple) {
      alert(
        this.singleFileMessageValue || 'You can only upload one file at a time.'
      )
      return
    }

    this.processFiles(event)
  }

  preventDefaultBehaviorAndStopPropagation(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  highlightDropzone() {
    this.dropzoneTarget.classList.remove('bg-white', 'border-neutral-200')
    this.dropzoneTarget.classList.add('bg-neutral-100', 'border-neutral-400')
  }

  unhighlightDropzone() {
    this.dropzoneTarget.classList.remove('bg-neutral-100', 'border-neutral-400')
    this.dropzoneTarget.classList.add('bg-white', 'border-neutral-200')
  }

  processFiles(event) {
    if (this.standaloneValue) {
      if (event.target.files) {
        this.addFileSizeToTotalSizeToUpload(event.target.files)
      } else {
        this.dropzoneFileInputTarget.files = event.dataTransfer.files
        this.dropzoneFileInputTarget.dispatchEvent(new Event('input'))
      }
    } else {
      let files = event.target.files
      if (!files) {
        files = event.dataTransfer.files
      }

      for (let i = 0; i < files.length; i++) {
        const fileIndex = this.fileTargets.length
        const fileItem = this.fileTemplateTarget.content.cloneNode(true)

        const previewImage = fileItem.querySelector('#preview-image')
        if (previewImage) {
          previewImage.src = URL.createObjectURL(files[i])
          previewImage.id = null
        }

        const previewFileName = fileItem.querySelector('#preview-file-name')
        if (previewFileName) {
          previewFileName.innerHTML = files[i].name
          previewFileName.id = null
        }

        const fileField = fileItem.querySelector('input[type=file]')
        const list = new DataTransfer()
        list.items.add(files[i])
        fileField.files = list.files
        fileField.id = null

        // Replace the item index with the file index
        // otherwise the form will not submit all the files
        //
        // eg. of a gallery with already 1 item
        // and then the fileTemplate would look
        // like this:
        //
        // gallery[items_attributes][1][file]
        // gallery[items_attributes][1][caption]
        //
        // if 2 files are added, the element
        // added to the form from the fileTemplate
        // would look like this:
        //
        // gallery[items_attributes][1][file]
        // gallery[items_attributes][1][caption]
        // gallery[items_attributes][2][file]
        // gallery[items_attributes][2][caption]
        //
        fileItem.querySelectorAll('input').forEach((input) => {
          input.name = input.name.replace(
            /\[\d+\](\[[^\[\]]+\])$/,
            `[${fileIndex}]$1`
          )
          input.id = `${input.id}-${fileIndex}`
        })

        if (this.dropzoneFileInputTarget.multiple) {
          this.filesTarget.appendChild(fileItem)
        } else {
          this.filesTarget.replaceChildren(fileItem)
        }
      }

      event.target.files = new DataTransfer().files
    }
  }

  updateSpecificFileUploadProgress(event) {
    const fileContainer = event.target.closest(
      '[data-ui--dropzone-target=file]'
    )
    const progressEl = fileContainer.querySelector('.UI-Dropzone-File-Progress')

    if (progressEl) {
      const progress = Math.max(1, Math.trunc(event.detail.progress))
      progressEl.textContent = `${progress}%`
    }
  }

  addFileSizeToTotalSizeToUpload(files) {
    this.totalSizeFilesUploading += Array.from(files).reduce(
      (total, file) => total + file.size,
      0
    )
  }

  updateGlobalUploadProgress(event) {
    const percentualFractionOfTotalSize =
      (100 / this.totalSizeFilesUploading) * event.detail.file.size
    const currentFileOverallProgress =
      (percentualFractionOfTotalSize / 100) * event.detail.progress
    const newProgress =
      this.totalPercentageAlreadyUploadedFiles + currentFileOverallProgress

    this.updateProgress(newProgress)
  }

  updateProgress(newProgress) {
    this.progressControllerElement.dispatchEvent(
      new CustomEvent('ui--progress:update', {
        detail: { progress: newProgress },
      })
    )
  }

  resetMultipleFilesProgressControl() {
    this.totalSizeFilesUploading = 0
    this.totalPercentageAlreadyUploadedFiles = 0
  }

  onSubmit(_event) {
    if (!this.standaloneValue) {
      this.dropzoneFileInputTarget.disabled = true
    }
  }

  onSubmitEnd(event) {
    if (!this.standaloneValue) {
      this.dropzoneFileInputTarget.disabled = false
    }

    if (
      event.detail.success &&
      this.standaloneValue &&
      this.dropzoneFileInputTarget.multiple
    ) {
      // Rails direct uploads assumes that after upload and form submission, the
      // page will be reloaded, thus, it keeps the original file input disabled
      // and maintains the new file inputs it created with the signed IDs of the
      // uploaded files. Since the Dropzone component doesn't know if the page
      // will be reloaded or not, we need to remove the file inputs created +
      // re-enable the original file input.
      //
      // The inputs with the signed IDs are always of the type "hidden".
      this.form
        .querySelectorAll(
          `input[type=hidden][name="${this.dropzoneFileInputTarget.name}"]`
        )
        .forEach((input) => {
          // Only remove inputs with a value (keep the original hidden input)
          if (input.value) {
            input.remove()
          }
        })

      // Re-enable the original file input + reset file list
      this.dropzoneFileInputTarget.files = new DataTransfer().files
    }
  }

  onRemoveFile(_event) {
    if (!this.dropzoneFileInputTarget.multiple) {
      const fileField = this.fileTarget.querySelector('input[type=file]')
      if (fileField) {
        fileField.remove()

        const resetedFileInput = document.createElement('input')
        resetedFileInput.type = 'text'
        resetedFileInput.classList.add('hidden')
        resetedFileInput.name = fileField.name
        resetedFileInput.id = fileField.id
        this.fileTarget.appendChild(resetedFileInput)
      }
    }
  }

  get processingAttribute() {
    return 'data-direct-uploads-processing'
  }

  get form() {
    return this.element.closest('form')
  }
}
