// Entry point for the build script in your package.json

import './helpers/sentry'
import 'trix'
import '@rails/actiontext'
import * as ActiveStorage from '@rails/activestorage'
import 'chartkick/chart.js'
import './components'

import './controllers'

ActiveStorage.start()

Trix.config.attachments.preview.caption.size = false
Trix.config.attachments.preview.caption.name = false
Trix.config.attachments.file.caption.size = false

import './data_turbo_confirm_override'
import './custom_turbo_stream_actions'
