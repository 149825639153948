import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['insertGateRow', 'insertGateButton']

  toggleGateRowVisibility(_event) {
    const listLastElement = !this.element.nextElementSibling
    const followedByGateRow =
      this.element.nextElementSibling?.classList.contains('gate-row')

    if (listLastElement || followedByGateRow) {
      this.insertGateRowTarget.classList.add('hidden')
    } else {
      this.insertGateRowTarget.classList.remove('hidden')
    }
  }

  toggleButtonVisibility(_event) {
    const listLastElement = !this.element.nextElementSibling
    const followedByGateRow =
      this.element.nextElementSibling?.classList.contains('gate-row')

    if (listLastElement || followedByGateRow) {
      this.insertGateButtonTarget.classList.add('hidden')
    } else {
      this.insertGateButtonTarget.classList.remove('hidden')
    }
  }
}
