import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['formField', 'selectedValue']

  selectedOption(event) {
    // Clearing the Turbo cache prevents Turbo from thinking it knows what
    // values to display on the form. Without it, Turbo will sometimes insert
    // erroneous values into the selectedValueTarget making the interaction
    // feel janky.
    Turbo.clearCache()
    this.selectedValueTarget.innerHTML = event.target.dataset.optionValue
    this.formFieldTarget.value = event.target.dataset.optionKey

    this.element.closest('form').requestSubmit()
  }
}
